import * as React from 'react';
import { config } from '@grafana/runtime';
import { AppState, CurrentProcessType, NewdashboardsProps, IsoClassificationsProps } from '../types/types';
import { getDashboardLanguage } from 'utils/helpers';

/**
 * Initialize default state and pass it to the main app component
 */

export const withData = (
  WrappedComponent: React.ComponentType<React.PropsWithChildren<{ initialState: AppState }>>
) => {
  // eslint-disable-next-line react/display-name
  return ({ children }: React.PropsWithChildren<{}>) => {
    const userPath = config.bootData.user;
    const dashboardLanguage = getDashboardLanguage();

    const defaultState = {
      user: {
        isGrafanaAdmin: userPath.isGrafanaAdmin,
        orgName: userPath.orgName,
        orgRole: userPath.orgRole,
      },
      oldJson: {},
      newJson: {},
      lang: dashboardLanguage,
      macAddress: '',
      error: {
        dashboardModel: '',
        customizedDashboard: '',
        notJson: '',
        badRequest: '',
      },
      isDashboardCustomized: false,
      isDashboardCreated: false,
      dashboardURL: '',
      analysisProfile: '',
      idCardValues: [],
      dashboardsToUpdate: [],
      dashboardsCreated: [] as NewdashboardsProps[],
      currentProcess: CurrentProcessType.null,
      isoClassifications: {} as IsoClassificationsProps,
      datasourceJson: {} as any,
    };

    const [initialState] = React.useState<AppState>(defaultState);
    //@ts-ignore
    return <WrappedComponent initialState={initialState}>{children}</WrappedComponent>;
  };
};
